<template>
  <div class="col-12 p-0 border_top">
    <div class="row m-0 align-items-center borders py-2">
      <!--IMAGE-->
      <div class="col-12 text-center col-md-auto px-4">
        <Image :imageName="donation.project.imageUrl" width="40" theClass="item_img" v-if="donation.project.imageUrl" />
        <img src="@/assets/images/Bismillah-Khutbah-1.png"  class="item_img" v-else>
      </div>
      <!--END IMAGE-->
      <div class="col-12 col-md p-0 text-center text-md-start mt-2  mt-md-0">
        <div class="row m-0">
          <div class="col-md-12 p-0 green-text bold item">
            {{donation.project.description}}
          </div>
          <div class="col-md-12 p-0 font12">
            {{ donation.currency.symbol }} {{ parseFloat(Math.round(donation.amount * 100) / 100).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ' ')}} {{ donation.donationType.description }}
          </div>
        </div>
      </div>
      <div class="col col-md-auto text-center text-md-end bold mt-3 mt-md-0 pe-4 item">
        {{ donation.currency.symbol }} {{ parseFloat(Math.round(donation.amount * 100) / 100).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ' ')}}
      </div>
    </div>
  </div>
</template>
<script>
import { defineAsyncComponent } from 'vue'

export default {
  components: {
    Image: defineAsyncComponent(() => import('@/components/Image.vue'))
  },
  name: 'ViewTheDonation',
  props: ['donation']
}
</script>
<style scoped>
.item_img {
  height: 40px;
  width: 40px;
  border-radius: 50%;
  object-fit: cover;
  background-color: rgba(0, 0, 0, 0.1);
}
.item {
  color: #4E5356;
  font-size: 15px;
}
.borders {
  border-bottom: 1px solid #E0E0E0;
}
.borders:hover {
  background-color: rgb(244, 244, 244);
}

</style>
