<template>
  <div class="row my-3 d-md-none">
    <div class="col-12 text-center">
      <Button color="dark" size="small" class="white-text" :btnText="btnText" icon2="arrow" @buttonClicked="$emit('close')">
        <template v-slot:iconStart>
          <IconArrowBack size="size16" />
        </template>
      </Button>
    </div>
  </div>
</template>
<script>
import { defineAsyncComponent } from 'vue'

export default {
  components: {
    Button: defineAsyncComponent(() => import('@/components/Button.vue')),
    IconArrowBack: defineAsyncComponent(() => import('@/components/icons/IconArrowBack.vue'))
  },
  name: 'MobileBackButton',
  props: ['btnText']
}
</script>
