<template>
  <div class="row m-0 justify-content-center">
    <!--DESKTOP-->
    <div class="col-11 px-0 my-3 d-none d-md-block">
      <div class="row align-items-center">
        <div class="col-4 text-center green-text">
          <Button color="grey" width="100" :btnText="displayTotal" />
        </div>
        <div class="col-4 text-center green-text">
          <Button color="grey" width="100" :btnText="frequency" />
        </div>
        <div class="col-4 text-center green-text">
          <Button color="grey" width="100" :btnText="date" />
        </div>
      </div>
      <div class="row mt-2">
        <div class="col-4 text-center">
          <div class="medium green-text font10 letter_spacing">
            DONATION TOTAL
          </div>
        </div>
        <div class="col-4 text-center">
          <div class="medium green-text font10 letter_spacing">
            FREQUENCY
          </div>
        </div>
        <div class="col-4 text-center">
          <div class="medium green-text font10 letter_spacing">
            NEXT PAYMENT
          </div>
        </div>
      </div>
    </div>
    <!--END DESKTOP-->
    <!--MOBILE-->
    <div class="col-12 px-0 my-3 d-md-none">
      <div class="row align-items-center">
        <div class="col-6 pe-1 text-center green-text">
          <Button color="grey" width="100" :btnText="frequency" />
        </div>
        <div class="col-6 ps-1 text-center green-text">
          <Button color="grey" width="100" :btnText="date" />
        </div>
      </div>
      <div class="row mt-2">
        <div class="col-6 pe-1 text-center medium green-text font10 letter_spacing">
          FREQUENCY
        </div>
        <div class="col-6 ps-1 text-center medium green-text font10 letter_spacing">
          NEXT PAYMENT
        </div>
      </div>
      <div class="row align-items-center mt-3">
        <div class="col-12 text-center green-text">
          <Button color="green" width="100" :btnText="displayTotal" />
        </div>
      </div>
      <div class="row mt-2">
        <div class="col-12 text-center medium green-text font10 letter_spacing">
          DONATION TOTAL
        </div>
      </div>
    </div>
    <!--END MOBILE-->
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import { defineAsyncComponent } from 'vue'

import { DateTime } from 'luxon'

export default {
  components: {
    Button: defineAsyncComponent(() => import('@/components/Button.vue'))
  },
  name: 'SummaryOptions',
  props: ['item'],
  data () {
    return {
      defaultCurrency: process.env.VUE_APP_CURRENCY_SYMBOL
    }
  },
  computed: {
    ...mapGetters(['user']),
    displayTotal () {
      return this.defaultCurrency + ' ' + parseFloat(Math.round(this.item.amount * 100) / 100).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
    },
    date () {
      const debitOrderDay = this.item.day
      const startMonth = DateTime.fromMillis(this.item.startDate).month
      const startYear = DateTime.fromMillis(this.item.startDate).year
      const startDate = DateTime.fromObject({ year: startYear, month: startMonth, day: debitOrderDay }).toMillis()
      const now = DateTime.now().ts
      const frequency = this.item.frequency
      let nextPaymentDate = null
      if (now > startDate) {
        if (frequency === 'DAILY') {
          nextPaymentDate = DateTime.now().startOf('day').plus({ days: 1 })
        } else if (frequency === 'WEEKLY') {
          const diff = Math.ceil(Math.abs(DateTime.fromMillis(startDate).diffNow('weeks').weeks))
          nextPaymentDate = DateTime.fromMillis(startDate).plus({ weeks: diff })
        } else if (frequency === 'MONTHLY') {
          const diff = Math.ceil(Math.abs(DateTime.fromMillis(startDate).diffNow('months').months))
          nextPaymentDate = DateTime.fromMillis(startDate).plus({ months: diff })
        }
      } else {
        nextPaymentDate = DateTime.fromMillis(startDate)
      }
      return DateTime.fromMillis(nextPaymentDate.ts).toFormat('yyyy-MM-dd')
    },
    frequency () {
      const lowercase = this.item.frequency.toLowerCase()
      return lowercase[0].toUpperCase() + lowercase.slice(1)
    }
  }
}
</script>
