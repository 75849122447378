<template>
  <FullPopup cols="col-11 col-md-8 col-lg-6 col-xxl-6" v-if="item">
    <ContentWithBackgroundAndFooter>
      <MobileBackButton btnText="Back to Recurring Donations" @close="$emit('closeView')"/>
      <div class="row my-3">
        <div class="col-12 text-end cursor d-none d-md-block lh-1" @click="$emit('closeView')">
          <ios-close-icon class="inline-icon green-text-dark" />
        </div>
        <div class="col-md-10 offset-md-1">
          <div class="row justify-content-center">
            <div class="col-auto text-center p-0 card_title">
              RECURRING DONATION: {{item.recurringDonationID}}
              <div class="row m-0 justify-content-center" >
                <div class="col-12 text-center pt-3 border_bottom">
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row m-0 justify-content-center" >
        <div class="col-md-11 px-0 my-3 card_item_inner">
          <div class="row mx-0 h-100 justify-content-center">
            <div class="col-12 p-0">
              <div class="row m-0 justify-content-center">
                <TheDonation v-for="(donation, key) in item.donations" :key="key" :donation="donation"/>
              </div>
            </div>
          </div>
        </div>
      </div>
      <SummaryOptions :item="item" />
    </ContentWithBackgroundAndFooter>
    <ActionButtons @edit="$emit('edit')" @editBank="$emit('editBank')" :item="item" @deleteOrder="$emit('deleteOrder')" />
  </FullPopup>
</template>
<script>
import { defineAsyncComponent } from 'vue'

import ContentWithBackgroundAndFooter from '@/components/styling/ContentWithBackgroundAndFooter.vue'
import FullPopup from '@/components/styling/FullPopup.vue'
import SummaryOptions from './views/SummaryOptions.vue'
import ActionButtons from './views/ActionButtons.vue'
import TheDonation from './views/TheDonation.vue'
import MobileBackButton from '../../components/MobileBackButton.vue'

export default {
  components: {
    'ios-close-icon': defineAsyncComponent(() => import('vue-ionicons/dist/ios-close.vue')),
    ContentWithBackgroundAndFooter,
    FullPopup,
    SummaryOptions,
    ActionButtons,
    TheDonation,
    MobileBackButton
  },
  name: 'ViewRecurring',
  props: ['item']
}
</script>
<style scoped>
.card_item_inner {
  background-color: rgb(249, 250, 251);
  border-radius: 15px;
  /* box-shadow: 2px 8px 23px 3px rgb(0 0 0 / 20%); */
  max-height: 80vh;
  overflow-y: auto;
  overflow-x: hidden;
}
.card_title {
  font-family: "quicksand_medium", Sans-serif;
  font-size: 14px;
  font-weight: 600;
  line-height: 1;
  color: var(--green-color-dark);
  letter-spacing: 4.3px;
}
.border_bottom {
  border-bottom: 1px solid var(--gold-color);
}
.item_img {
  height: 40px;
  width: 40px;
  border-radius: 50%;
  object-fit: cover;
  background-color: rgba(0, 0, 0, 0.1);
}
.item {
  color: #4E5356;
  font-size: 15px;
}
.borders {
  border-bottom: 1px solid #E0E0E0;
}
.borders:hover {
  background-color: rgb(244, 244, 244);
}

</style>
