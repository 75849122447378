<template>
  <div class="col-12 px-4 align-self-end background-green">
    <div class="row mx-0 py-3 align-items-center justify-content-center">
      <div class="col-md-4 px-1 mb-2 mb-md-0 order-last order-md-first" >
        <Button color="red" size="" width="100" btnText="Cancel" icon="arrow" @buttonClicked="$emit('deleteOrder')">
          <ios-close-circle-outline-icon class="inline-icon" />
        </Button>
      </div>
      <div class="col-md-4 px-1 mb-2 mb-md-0" >
        <Button color="green_light" size="" width="100" btnText="Edit Donation" icon="arrow" @buttonClicked="$emit('edit')">
          <IconEdit size="size16"/>
        </Button>
      </div>
      <div class="col-md-4 px-1 mb-2 mb-md-0" v-if="item.recurringType === 'DEBIT_ORDER'">
        <Button color="green_light" size="" width="100" btnText="Edit Bank Details" icon="arrow" @buttonClicked="$emit('editBank')">
          <IconBank size="size16"/>
        </Button>
      </div>
      <div class="col-md-4 px-1 mb-2 mb-md-0" v-else-if="item.recurringType === 'CREDIT_CARD'">
        <Button color="green_light" size="" width="100" btnText="Edit Card Details" icon="arrow" disabled v-if="item.recurringDonationStatus && item.recurringDonationStatus.recurringDonationStatusID === 9">
          <IconCard size="size16"/>
        </Button>
        <Button color="green_light" size="" width="100" btnText="Edit Card Details" icon="arrow" @buttonClicked="updateCard" v-else>
          <IconCard size="size16"/>
        </Button>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions } from 'vuex'

import { defineAsyncComponent } from 'vue'

export default {
  components: {
    Button: defineAsyncComponent(() => import('@/components/Button.vue')),
    IconBank: defineAsyncComponent(() => import('@/components/icons/IconBank.vue')),
    IconCard: defineAsyncComponent(() => import('@/components/icons/IconCard.vue')),
    IconEdit: defineAsyncComponent(() => import('@/components/icons/IconEdit.vue')),
    'ios-close-circle-outline-icon': defineAsyncComponent(() => import('vue-ionicons/dist/ios-close-circle-outline.vue'))
  },
  name: 'ActionButtons',
  props: ['item'],
  data () {
    return {
      isLoading: false
    }
  },
  computed: {
    isUK () {
      if (process.env.VUE_APP_COUNTRY === 'UK') {
        return true
      } else {
        return false
      }
    }
  },
  methods: {
    ...mapActions([
      'updateCardDetails'
    ]),
    async updateCard () {
      this.isLoading = true
      const ret = await this.updateCardDetails(this.item.recurringDonationID)
      if (ret && ret.data) {
        window.open(ret.data.url, '_blank')
        this.isLoading = false
      }
      this.isLoading = false
    }
  }
}
</script>
