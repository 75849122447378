<template>
  <div class="row m-0 justify-content-center holder">
    <div class="bg_holder">
      <div class="background" >
      </div>
    </div>
    <div class="col-12 px-4 above ">
      <slot />
    </div>
  </div>
</template>

<script>

export default {
  name: 'ContentWithBackground'
}
</script>

<style scoped>

.holder {
  position: relative;
}
.above {
  z-index: 1;
  color: var(--green-color-dark);
}
 .bg_holder {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 1;
  background-image: url(../../assets/images/bg.png);
  background-size: 32% auto;
  border-top-left-radius: 13px;
  border-top-right-radius: 13px;
  max-width: 100%;
  width: 100%;
  height: 100%;
  padding: 0;
}
.background {
  background-image: linear-gradient(180deg, #fffffff7 0%, #ffffff 100%);
  position: absolute;
  border-top-left-radius: 13px;
  border-top-right-radius: 13px;
  background-position: top center;
  height: 100%;
  width: 100%;
  z-index: 1;
}
</style>
